import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { SSRConfig } from 'next-i18next';
import {
  GetGeneralPageStaticPropsParams,
  getGeneralPageStaticProps,
  GetGeneralPageStaticPropsResponse,
  GeneralPage,
  GetGeneralPageStaticPropsContext,
  Website,
} from 'ui';

export async function getStaticProps(
  context: GetStaticPropsContext<GetGeneralPageStaticPropsParams>,
): Promise<GetStaticPropsResult<SSRConfig & GetGeneralPageStaticPropsResponse>> {
  const res = await getGeneralPageStaticProps({ website: Website.Shopper })(context as GetGeneralPageStaticPropsContext);

  return { props: { ...res.props, ...(await serverSideTranslations(res.props.locale ?? 'en', ['common'])) } };
}

export default GeneralPage;
